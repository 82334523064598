import React from 'react';
import { AmountToPay } from '../AmountToPay';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';
import { Row } from '../Layout';

const ETransferPayment = ({ transaction, setTransaction }) => {
  return (
    <>
      <Row>
        <AmountToPay transaction={transaction} setTransaction={setTransaction} />
        <ReferenceIdTextInput transaction={transaction} setTransaction={setTransaction} />
      </Row>
    </>
  );
};

export default ETransferPayment;
